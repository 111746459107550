/**
 * Trim string to specified limit and add ellipsis. This will trim down to
 * length while keeping words intact which can be useful in certain instances
 * over a pure CSS approach.
 * @param {String} input
 * @param {Number} limit
 */
export default (input, limit) => {
  if (!input || !limit || input.length < limit) return input

  const trimmed = input.substr(0, limit)
  return trimmed.substr(0, Math.min(trimmed.length, trimmed.lastIndexOf(' '))) + '...'
}
