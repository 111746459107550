/**
 * Format a number as currency.
 * @param {String} input
 * @param {Object} options - Optional configuration for `toLocaleString`
 */
export default input => {
  if (!input) return input
  const str = String(input)
  if (!str.startsWith('-')) return str
  return '(' + str.substring(1) + ')'
}
