
export default {
  mounted () {
    window.addEventListener('keyup', this.onKeyPressed)
  },
  destroyed () {
    window.removeEventListener('keyup', this.onKeyPressed)
  },
  methods: {
    onKeyPressed (event) {
      if (event.keyCode === 27) {
        this.onEscPressed()
      }
    }
  }
}
