<script>
import brand from '../tokens/brand'
import { colorFromString } from '../vue/color'

export default {
  name: 'AcvMaxWordmark',
  props: {
    colorPrimary: {
      type: String,
      default: brand.acvPrimary
    },
    colorSecondary: {
      type: String,
      default: brand.acvSecondary
    },
    white: {
      type: Boolean,
      default: false
    },
    width: {
      type: [Number, String],
      default: null
    }
  },
  computed: {
    acvLogoColor: vm => vm.white ? brand.acvWhite : colorFromString(vm.colorPrimary),
    maxLogoColor: vm => vm.white ? brand.acvWhite : colorFromString(vm.colorSecondary)
  }
}
</script>

<template>
  <svg
    :width="width"
    viewBox="0 0 371 120"
    fill="none"
  >
    <path
      d="M100.6 59.7c-3 24.2 13.8 43.8 37.5 43.8 12.2 0 23.8-5.2 32.7-13.5l-16.4-16c-3.6 3-8.2 4.8-12.9 4.8-10.1 0-17.3-8.4-16.1-18.7 1.3-10.3 10.5-18.7 20.7-18.7 5.8 0 10.7 2.8 13.5 7.1l18.5-19C171.4 21.1 161 16 148.9 16c-23.7 0-45.3 19.6-48.3 43.7ZM74.5 58l1.7 12.6h-9.6l-11 17.7h22.9L80 99.6h25.8l-1.4-10.2c-4.7-6.9-7.2-15.1-7.1-23.9 0-2 .1-4.1.4-6.2.3-2.7.9-5.3 1.6-7.9l-3.7-27.6L74.5 58ZM61.9 19.9 0 119.4h33.3l61.9-99.5H61.9Zm108.8 0c4.1 2.4 7.7 5.6 11.1 9.6l-14.7 15.1L174 96l21.2-34.1-5.6-42h-18.9Zm66-19.9-61.9 99.7h33.3l7.3-11.8-.1-32.9-5.8-7.2 24.9-.2 3.5 4.1L270 0h-33.3Z"
      :fill="acvLogoColor"
    />
    <path
      d="M298.2 62.5L305.7 81.8H291.8L298.2 62.5ZM344.7 49.8L347.9 54.4L342.8 61.4L333.7 49.8H315.3L333.6 74.3L324 87.5L308.9 49.7H282.6L286 54.3L274.2 85.5V49.7H258L245.4 65.1L233 49.9H214.3L217.9 54.5V99.7H232.7V74.2L245.9 89.1L259.5 74.2V99.7H285.5L287.6 93.5H310.3L312.7 99.7H334.6L343.3 87.5L352 99.7H371L352.6 74.8999L370.4 49.9H344.7V49.8Z"
      :fill="maxLogoColor"
    />
  </svg>
</template>
