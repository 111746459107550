import merge from 'merge'

import * as tokensDefault from './tokens'
import parse from './utils/palette'

/**
 * Default tokens as defined by the design system. This is read-only to
 * prevent child themes from mutating the base theme. Use `extend` to create
 * and return new theme tokens with OEM or Product level overrides.
 * @return {Object}
 */
export const tokens = tokensDefault

/**
 * Override the base theme with a set of OEM or Product level overrides.
 * @param {Object} theme - Only the tokens that are meant to be overridden.
 * These will be merged with the base theme tokens. New properties can be
 * added if necessary but the existing namespacing should be observed.
 * @return {Object}
 */
export const extend = (theme = {}) => {
  if (theme.colors) theme.colors = parse(theme.colors, this.tokens.colors)
  return merge(this.tokens, theme)
}
