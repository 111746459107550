<script>
import PopoverArrow from './PopoverArrow.vue'
import { phone } from '~/ui/vue/filters'

export default {
  name: 'MenuHelp',
  filters: {
    phone
  },
  components: {
    PopoverArrow
  },
  data: () => ({
    techSupportPhone: '8773785665',
    techSupportEmail: 'helpdesk@acvmax.com'
  }),
  computed: {
    accountManager () {
      return this.$auth?.dealer?.accountManager?.profile
    }
  }
}
</script>

<template>
  <VMenu
    bottom
    content-class="v-menu__content--popover"

    nudge-left="24"
    nudge-bottom="2"
    offset-y
    transition="slide-y-transition"
    :close-on-content-click="false"
  >
    <template #activator="{ on }">
      <VBtn
        :ripple="false"
        class="v-toolbar__nav--help"
        icon
        v-on="on"
      >
        <VIcon>$help</VIcon>
      </VBtn>
    </template>
    <VSheet class="v-sheet-popover">
      <PopoverArrow />
      <div class="v-sheet-popover__contents py-4 px-3">
        <div class="body-2 mb-2">
          Technical Support
        </div>
        <a :href="`tel:${techSupportPhone}`">
          {{ techSupportPhone | phone }}
        </a>
        <br>
        <a :href="`mailto:${techSupportEmail}`">
          {{ techSupportEmail }}
        </a>
      </div>
      <VDivider />
      <div
        v-if="accountManager"
        class="v-sheet-popover__contents py-4 px-3"
      >
        <div class="body-2 mb-2">
          Training &amp; Questions
        </div>
        {{ accountManager.name }}
        <br v-if="accountManager.phone">
        <a :href="`tel:${accountManager.phone}`">
          {{ accountManager.phone | phone }}
        </a>
        <br>
        <a :href="`mailto:${accountManager.email}`">
          {{ accountManager.email }}
        </a>
      </div>
    </VSheet>
  </VMenu>
</template>

<style lang="scss">
.v-toolbar__nav--help.v-btn::before {
  display: none;
}

.v-menu__content--popover {
  box-shadow: unset;
  contain: unset;
  overflow: visible;

  .v-sheet-popover {
    border-radius: $border-radius-root;
    font-size: 12px;
    line-height: 18px;
    width: 216px;

    &.theme--dark {
      /* stylelint-disable-next-line function-no-unknown */
      background: map_get($material-dark-elevation-colors, "1");
      box-shadow: 0 0 15px rgba(0 0 0 / 25%), 0 0 0 1px rgb(255 255 255 / 10%);
    }

    &.theme--light {
      background: #FFF;
      box-shadow: 0 0 15px rgb(0 0 0 / 15%), 0 0 0 1px rgb(0 0 0 / 10%);
    }

    a {
      color: inherit;
      opacity: 0.7;
      text-decoration: none;
    }
  }

  .popover__arrow {
    position: absolute;
    transform: rotate(180deg) translateY(13px) translateX(-32px);
  }
}
</style>
