/**
 * Consistent formatting for phone numbers, since some dealer phone numbers
 * come back as a string with only the digits.
 * @param {String} input
 */
export default input => {
  if (!input) return
  const digits = input.replace(/[^0-9A-Z]/g, '').slice(-10)
  return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`
}
