import core from 'vuetify/lib/util/colors'

// Colours from any of the core Material Design palettes can be used.
// @see https://vuetifyjs.com/en/style/colors#material-colors

import parse from '../utils/palette'
// Brand colors are defined separately.
import brand from './brand'

/**
 * Theme elements are used primarily in applications and should be semantically
 * named. Avoid using a description of the color as the name of the color, since
 * this can change and will lose it's contextual meaning. The following defaults
 * are set by the theme system if not overridden here:
 *   primary: '#1976D2'
 *   secondary: '#424242'
 *   accent: '#82B1FF'
 *   error: '#FF5252'
 *   info: '#2196F3'
 *   success: '#4CAF50'
 *   warning: '#FFC107'
 */
const theme = {
  accent: brand.green,
  'acv-primary': brand.acvPrimary,
  'acv-secondary': brand.acvSecondary,
  badge: '#6698c1',
  dark: { base: '#1E242D' },
  error: core.red.accent2,
  favorite: { base: '#E05358' },
  grey: { ...core.grey },
  info: { ...core.blue },
  light: { base: brand.white },
  primary: brand.blue,
  rating: { ...core.amber },
  secondary: brand.blueLight,
  success: { ...core.green },
  warning: core.orange.darken1
}

export default parse(theme)
