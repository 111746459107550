import { format, parse } from 'fecha'

/**
 * Re-format a timestamp as a human-readable date.
 * @param {String} input
 * @param {Object} outputFormat - Optional configuration for `toLocaleString`
 * @see https://www.npmjs.com/package/fecha#formatting-tokens
 * @param {Object} inputFormat - If the date is already in ISO 8601 format,
 * this parameter can be considered optional. Otherwise, to ensure the source
 * date is parsed correctly, describe it's format here.
 * @see https://www.npmjs.com/package/fecha#parsing
 */
export default (input, outputFormat, inputFormat) => {
  if (!input) return input
  input = inputFormat ? parse(input, inputFormat) : new Date(input)
  return format(input, outputFormat)
}
