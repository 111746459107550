/**
 * In most cases, brand colors should not be referenced directly in the app
 * itself but may be referenced in the theme colors below.
 */
export default {
  acvPrimary: '#F26522',
  acvSecondary: '#1D3865',
  acvWhite: '#FFFFFF',
  blue: '#1C3664',
  blueLight: '#2EA4DC',
  green: '#8BC63F',
  white: '#EFF0EF',
  yellowGreen: '#CDDC28'
}
