/**
 * Capitalize each word or a defined number of words in a string.
 * @param {String} input
 * @param {Object} config
 * @param {String|RegExp} config.delimiter - Character(s) used as a word boundary.
 * @param {Number} config.limit - The maximum number of words to capitalize.
 */
export default (input, {
  delimiter = ' ',
  limit = Infinity
} = {}) => {
  if (!input) return input

  return input.toLowerCase()
    .split(delimiter)
    .map((s, i) => i >= limit ? s : s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

/**
 * Optionally convert incoming camelCase or PascalCase values before capitalizing.
 * Due to the use of RegExp and corresponding performance penalty, it is opt-in.
 */
const NORMALIZE_REGEXP = /([A-Z](?=[A-Z][a-z])|[^A-Z](?=[A-Z])|[a-zA-Z](?=[^a-zA-Z]))/g
export const normalize = (str = '') => str.replace(NORMALIZE_REGEXP, '$1 ')
