const formatter = options => Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  ...options
})

const defaultFormatter = formatter()

/**
 * Format a number as currency.
 * @param {String} input
 * @param {Object} options - Optional configuration for `toLocaleString`
 */
export default (input, options) => {
  if (isNaN(Number(input))) return input
  if (!options) {
    return defaultFormatter.format(input)
  }
  options.digits = options.digits === true
    ? input % 1 ? 2 : 0
    : Number(options.digits) || 0
  return formatter({
    maximumFractionDigits: options.digits,
    minimumFractionDigits: options.digits,
    ...options
  }).format(input)
}
