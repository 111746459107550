/**
 * URL and Filename safe Base64 encoding.
 * @see https://tools.ietf.org/html/rfc4648#page-7
 */
export const encode = input => {
  if (typeof btoa === 'undefined') {
    input = Buffer.from(input).toString('base64')
  } else {
    input = btoa(input)
  }

  return input.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

/**
 * URL and Filename safe Base64 decoding.
 * @see https://tools.ietf.org/html/rfc4648#page-7
 */
export const decode = input => {
  input = input.replace(/-/g, '+').replace(/_/g, '/')

  if (typeof atob === 'undefined') {
    input = Buffer.from(input, 'base64').toString('utf-8')
  } else {
    input = atob(input)
  }

  return input
}
